import React, { useContext, useEffect } from 'react'
import PostalCodeLocationInput from './PostalCodeLocationInput.jsx'
import CountrySelect from './CountrySelect.jsx'
import ContentContext from '../../Context/ContentContext'
import { useFormContext } from 'react-hook-form'
import { shouldRequestQrCode } from '../../qrCodeService'
import phoneNumberValidation from "../../helpers/phoneNumberValidation";
import { shouldRequestLabelFreePilot } from "../../labelFreePilotService";

const addressForm = ({ formFor, email, onChange, receiver, setValidPostCode, senderCountryCode, recipientCountryCode }) => {

  const renderSenderPhoneNumberInput = (isSender) => {
    return isSender && <label className='hw-label'>
      {ctx.t('formLabels.phoneNumber')}
      <input
        className={errors[formFor]?.phoneNumber ? 'hw-input hw-input--error' : 'hw-input'}
        type='text'
        name={formFor + '.phoneNumber'}
        data-testid={`${formFor}PhoneNumberInputField`}
        {...register(formFor + '.phoneNumber', {
          pattern: phoneNumberValidation(senderCountryCode,ctx.t),
          maxLength: {
            value: 35,
            message: ctx.t('errormsg.fieldlength')
          },
          onChange: onChange
        })}
      />
      {errors[formFor]?.phoneNumber && <span className='hw-error'>{errors[formFor]?.phoneNumber?.message}</span>}
    </label>
  }
  const ctx = useContext(ContentContext)
  const { register, formState: {errors}, setValue, watch } = useFormContext()

  useEffect(() => {
    if (receiver) {
      setValue('recipient.name', receiver.name)
      setValue('recipient.addressLine1', receiver.addressLine1)
      setValue('recipient.addressLine2', receiver.addressLine2)
      if (email) {
        setValue('recipient.email', receiver.email)
      }
      setValue('recipient.postalCode', receiver.postalCode)
      setValue('recipient.postalCity', receiver.postalCity)
      setValue('recipient.countryCode', receiver.countryCode)
    }
  }, [receiver])

  const showConfirmationPage = shouldRequestQrCode(receiver.returnProduct, senderCountryCode, receiver.valueAddedServices?.includes('1288'))
    || shouldRequestLabelFreePilot(receiver.apiCustomerNumber, receiver.returnProduct, senderCountryCode, watch('sender.postalCode') ?? '')

  return (
    <>
      <label className='hw-label'>
        {ctx.t('formLabels.name')}
        <input
          className={errors[formFor]?.name ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          name={formFor + '.name'}
          data-testid={`${formFor}NameInputField`}
          {...register(formFor + '.name', {
            required: ctx.t('errormsg.cannotbeempty'),
            maxLength: {
              value: 35,
              message: ctx.t('errormsg.fieldlength')
            },
            onChange: onChange
          })}
          autoFocus
        />
        {errors[formFor]?.name && <span className='hw-error'>{errors[formFor]?.name?.message}</span>}
      </label>
      <label className='hw-label'>
        {ctx.t('formLabels.address')}
        <input
          className={errors[formFor]?.addressLine1 ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          name={formFor + '.addressLine1'}
          data-testid={`${formFor}AddressInputField`}
          {...register(formFor + '.addressLine1', {
            required: ctx.t('errormsg.cannotbeempty'),
            maxLength: {
              value: 35,
              message: ctx.t('errormsg.fieldlength')
            },
            onChange: onChange
          })}
        />
        {errors[formFor]?.addressLine1 && <span className='hw-error'>{errors[formFor]?.addressLine1?.message}</span>}
      </label>
      <label className='hw-label'>
        {ctx.t('formLabels.addressline2')}
        <input
          className={errors[formFor]?.addressLine2 ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          name={formFor + '.addressLine2'}
          data-testid={`${formFor}AddressLine2InputField`}
          {...register(formFor + '.addressLine2', {
            maxLength: {
              value: 35,
              message: ctx.t('errormsg.fieldlength')
            },
            onChange: onChange
          })}
        />
        {errors[formFor]?.addressLine2 && <span className='hw-error'>{errors[formFor]?.addressLine2?.message}</span>}
      </label>
        {renderSenderPhoneNumberInput(formFor === "sender")}
      {email &&
        <label className='hw-label'>
          { showConfirmationPage ? ctx.t('formLabels.email') : ctx.t('formLabels.emailWithoutQrCode') }
          <input
            className={errors[formFor]?.email ? 'hw-input hw-input--error' : 'hw-input'}
            type='email'
            name={formFor + '.email'}
            data-testid={`${formFor}EmailInputField`}
            {...register(formFor + '.email', {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: ctx.t('errormsg.emailformat')
              },
              maxLength: {
                value: 35,
                message: ctx.t('errormsg.fieldlength')
              },
              onChange: onChange
            })}
          />
          {errors[formFor]?.email && <span className='hw-error'>{errors[formFor]?.email?.message}</span>}
        </label>}
      <PostalCodeLocationInput
        formFor={formFor}
        onChange={onChange}
        setValidPostCode={setValidPostCode}
        senderCountryCode={senderCountryCode}
        recipientCountryCode={recipientCountryCode}
      />
      <CountrySelect formFor={formFor} onChange={onChange} />
    </>
  )
}

export default addressForm
