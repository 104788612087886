var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { contentContext } from '../../Context/ContentContext';
function SaturdayDeliveryDialog(_a) {
    var _this = this;
    var saturdayVas1062IsPresent = _a.saturdayVas1062IsPresent, fromPostalCode = _a.fromPostalCode, toPostalCode = _a.toPostalCode, validSenderPostCode = _a.validSenderPostCode, validRecipientPostCode = _a.validRecipientPostCode;
    var _b = useState({
        eligibleValueAddedServices: [],
    }), applicableVases = _b[0], setApplicableVases = _b[1];
    var currentSenderPostalCodeIsValid = validSenderPostCode != "" && validSenderPostCode === fromPostalCode;
    var currentRecipientPostalCodeIsValid = validRecipientPostCode != "" && validRecipientPostCode === toPostalCode;
    var ctx = useContext(contentContext);
    var register = useFormContext().register;
    var saturdayDeliveryDialog = function (eligibleForSaturdayDeliveryDescription, confirmed) {
        return (React.createElement("a", { className: "hw-card", "data-testid": confirmed ? "saturdayDelivery1062" : "saturdayDeliveryUnconfirmed1062" },
            React.createElement("div", { className: "hw-card__body" },
                React.createElement("div", { className: "hw-block--mb-smaller" },
                    React.createElement("i", { className: "fas fa-lg fa-exclamation-circle hw-info-box__icon hw-block--mr-smaller", "aria-hidden": "true" }),
                    React.createElement("span", { style: { paddingLeft: "1rem" } }, eligibleForSaturdayDeliveryDescription)),
                React.createElement("label", { className: "hw-checkbox" },
                    React.createElement(React.Fragment, null,
                        ctx.t('saturdayDelivery.checkboxText'),
                        React.createElement("input", __assign({ type: "checkbox", name: "sender.apply1062Vas" }, register('sender.apply1062Vas'))),
                        React.createElement("i", { className: "hw-checkbox__indicator" }))))));
    };
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(saturdayVas1062IsPresent && currentSenderPostalCodeIsValid && currentRecipientPostalCodeIsValid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetch("/sg?fromPostalCode=".concat(fromPostalCode, "&toPostalCode=").concat(toPostalCode)).then(function (res) { return res.json(); })];
                    case 1:
                        res = _a.sent();
                        setApplicableVases(res);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })();
    }, [saturdayVas1062IsPresent, currentSenderPostalCodeIsValid, currentRecipientPostalCodeIsValid]);
    if ([saturdayVas1062IsPresent, currentSenderPostalCodeIsValid, currentRecipientPostalCodeIsValid].some(function (x) { return !x; })) {
        return null;
    }
    if (applicableVases.eligibleValueAddedServices.some(function (x) { return x === "1062"; })) {
        return saturdayDeliveryDialog(ctx.t('saturdayDelivery.eligible'), true);
    }
    if (applicableVases.eligibleValueAddedServices.some(function (x) { return x === "1062_UNCONFIRMED"; })) {
        return saturdayDeliveryDialog(ctx.t('saturdayDelivery.eligibleUnconfirmed'), false);
    }
    return null;
}
export default SaturdayDeliveryDialog;
