import { env } from "./config/envConfig";
export function shouldRequestLabelFreePilot(customerNumber, service, senderCountryCode, senderPostalCode) {
    var isApplicableEnvironment = env.includes('localhost') || env.includes('test') || env.includes('qa');
    var isApplicableCustomer = ['20019475837', '20000959849'].includes(customerNumber);
    var isApplicableSenderCountry = senderCountryCode === 'NO';
    var applicableServices = ['9000', '9300', '9350', 'PICKUP_PARCEL_RETURN_BULK'];
    var isApplicableService = applicableServices.includes(service);
    var applicablePostalCodePrefix = ['31', '32', '37', '38', '39'];
    var isApplicablePostalCode = applicablePostalCodePrefix.some(function (postalCode) { return senderPostalCode.startsWith(postalCode); });
    return isApplicableCustomer && isApplicableSenderCountry && isApplicableService && isApplicablePostalCode;
}
