import React, { useState, useContext, useEffect } from 'react'
import AddressForm from './AddressForm/AddressForm.jsx'
import ReceiverAddressCard from './ReceiverAddressCard.jsx'
import ContentContext from '../Context/ContentContext.js'
import GenerateLabelBtn from './GenerateLabelBtn.jsx'
import PackagesAndRef from './AddressForm/PackagesAndRef.jsx'
import { useForm, FormProvider } from 'react-hook-form'
import * as Sentry from '@sentry/browser'
import api from '../api'
import {saturdayDelivery1062VasIsPresent} from '../helpers/saturdayDelivery.ts';
import { shouldRequestQrCode } from '../qrCodeService'
import WeightAndDimensions from './AddressForm/WeightAndDimensions.jsx'
import SaturdayDeliveryDialog from './AddressForm/SaturdayDeliveryDialog.tsx'
import { removeAllWhitespaceInString } from '../helpers/stringHelpers'
import { cloneDeep } from 'lodash'
import { shouldRequestLabelFreePilot } from "../labelFreePilotService";

const returForms = ({ authCode, setFetchError, setConfirmation, reference }) => {
  const context = useContext(ContentContext)
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      sender: {
        countryCode: context.receiverAddresses[0].appliesToCountryCode,
        numberOfPackages: 1
      }
    }
  })

  useEffect(() => {
    reset({
      sender: {
        name: null,
        addressLine1: null,
        addressLine2: null,
        phoneNumber: null,
        email: null,
        postalCode: null,
        postalCity: null,
        countryCode: context.receiverAddresses[0].appliesToCountryCode,
        reference: reference,
        numberOfPackages: 1,
        weightAndDimensions: null
      }
    })
    setShowRecipientForm(false)
  }, [context.receiverAddresses])

  const findRightReceiverAddress = (arr) => {
    return arr.length >= 1 && arr.find(address => address.appliesToCountryCode === getValues('sender.countryCode'))
      ? arr.find(address => address.appliesToCountryCode === getValues('sender.countryCode'))
      : arr[0]
  }

  const { getValues, formState, reset, handleSubmit, watch } = methods
  const [showRecipientForm, setShowRecipientForm] = useState(false)
  const [validSenderPostCode, setValidSenderPostCode] = useState("");
  const [validRecipientPostCode, setValidRecipientPostCode] = useState(findRightReceiverAddress(context.receiverAddresses).postalCode)
  const [labelURL, setLabelURL] = useState('')

  const onChangeLabelURLReset = () => {
    if (labelURL !== '' && formState.isSubmitted) {
      setLabelURL('')
      reset(getValues())
    }
  }

  useEffect(() => {
    if (!showRecipientForm) {
      setValidRecipientPostCode(findRightReceiverAddress(context.receiverAddresses).postalCode)
    }
  }, [showRecipientForm])

  useEffect(() => {
    if (!selectedReceiver.editable) {
      setShowRecipientForm(false)
    }
  }, [watch('sender.countryCode')])

  const receiverTitleGrid = {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto fit-content(50%)',
    gridColumnGap: '0.5rem'
  }

  const selectedReceiver = findRightReceiverAddress(context.receiverAddresses)

  const senderCountryCode = selectedReceiver.appliesToCountryCode
  const recipientCountryCode = showRecipientForm ? watch('recipient.countryCode') : selectedReceiver.countryCode
  const senderPostalCode = watch('sender.postalCode')
  const recipientPostalCode = showRecipientForm ? watch('recipient.postalCode') : selectedReceiver.postalCode

  const showConfirmationPage = shouldRequestQrCode(selectedReceiver.returnProduct, senderCountryCode, selectedReceiver.valueAddedServices?.includes('1288'))
    || shouldRequestLabelFreePilot(selectedReceiver.apiCustomerNumber, selectedReceiver.returnProduct, senderCountryCode, senderPostalCode ?? '')
  const maxColli = selectedReceiver.returnProduct === '9650' ? 1 : 10

  const toggleShowRecipientForm = () => setShowRecipientForm(!showRecipientForm)

  const useDefaultReceiverbutton = (
    <button
      className='hw-button hw-button--small hw-button--secondary'
      data-testid='useDefaultBtn'
      onClick={(e) => {
        e.preventDefault()
        toggleShowRecipientForm()
      }}
    >
      {context.t('btnlabels.usedefault')}
    </button>
  )

  const generateLabel = () => {
    const sender = getValues('sender')
    if (!sender.numberOfPackages) {
      sender.numberOfPackages = sender.weightAndDimensions?.length?.toString()
    }

    const cleanPostalCodeForAddress = (senderOrRecipient) => {
      const senderOrRecipientClone = cloneDeep(senderOrRecipient)
      senderOrRecipientClone.postalCode = removeAllWhitespaceInString(senderOrRecipientClone.postalCode)
      return senderOrRecipientClone;
    }

    api.generateLabel({
      authCode: authCode,
      sender: cleanPostalCodeForAddress(sender),
      recipient: showRecipientForm ? { ...selectedReceiver, ...cleanPostalCodeForAddress(getValues('recipient')) } : selectedReceiver
    })
      .then(resp => {
        const response = {}
        response.resp = resp
        response.sender = cleanPostalCodeForAddress(sender)
        response.recipient = showRecipientForm ? { ...selectedReceiver, ...cleanPostalCodeForAddress(getValues('recipient')) } : selectedReceiver
        showConfirmationPage ? setConfirmation(response) : setConfirmation('')
        setLabelURL(response.resp.links.labels)
      })
      .catch(error => {
        Sentry.captureException(error)
        setFetchError(true)
        setLabelURL('')
      })
  }

  return (
    <FormProvider {...methods}>
      <form
        data-testid='returnForms'
        className='hw-flex hw-flex--twos hw-flex--gutter-larger-desktop'
        onSubmit={handleSubmit(generateLabel)}
      >
        <div className='hw-grid__item hw-large--two-fifths hw--one-whole'>
          <h3 className='hw-block--mb-smallest'>{context.t('sender')}</h3>
          <AddressForm
            formFor='sender' email={true} onChange={onChangeLabelURLReset}
            receiver={selectedReceiver}
            setValidPostCode={setValidSenderPostCode}
            senderCountryCode={senderCountryCode}
            recipientCountryCode={recipientCountryCode}
          />
          <SaturdayDeliveryDialog
            fromPostalCode={senderPostalCode}
            toPostalCode={recipientPostalCode}
            saturdayVas1062IsPresent={saturdayDelivery1062VasIsPresent(selectedReceiver?.valueAddedServices ?? [])}
            validSenderPostCode={validSenderPostCode}
            validRecipientPostCode={validRecipientPostCode}
          />
          <PackagesAndRef
            maxColli={maxColli} onChange={onChangeLabelURLReset}
            countryCode={senderCountryCode}
            reference={reference}
          />
          {
            senderCountryCode === 'FI' &&
              <>
                <h3 className='hw-block--mb-smallest'>{context.t('packages')}</h3>
                <WeightAndDimensions
                  onChange={onChangeLabelURLReset}
                  formFor='sender'
                  product={selectedReceiver.returnProduct}
                  senderCountryCode={senderCountryCode}
                  recipientCountryCode={recipientCountryCode}
                  maxColli={maxColli > 10 ? 10 : maxColli}
                />
              </>
          }
        </div>
        <div className='hw-grid__item hw-large--two-fifths hw--one-whole'>
          <div style={receiverTitleGrid}>
            <h3 className='hw-block--mb-smallest'>{context.t('recipient')}</h3>
            {showRecipientForm && useDefaultReceiverbutton}
          </div>
          {
            showRecipientForm
              ? <AddressForm
                  setValidPostCode={setValidRecipientPostCode}
                  formFor='recipient'
                  onChange={onChangeLabelURLReset}
                  receiver={selectedReceiver}
                  senderCountryCode={senderCountryCode}
                  recipientCountryCode={recipientCountryCode}
                />
              : <ReceiverAddressCard toggle={toggleShowRecipientForm} receiver={selectedReceiver} />
          }
        </div>
        <GenerateLabelBtn
          labelURL={labelURL}
          setLabelURL={setLabelURL}
          open={!showConfirmationPage}
        />
      </form>
    </FormProvider>
  )
}

export default returForms
